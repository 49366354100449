import React, { useContext } from 'react'

import { COLOR } from '@farewill/ui/tokens'
import { SplitContext } from '@splitsoftware/splitio-react'

import DefaultLayout from 'layouts/DefaultLayout'
import FUNERAL_PLANS_JSONLD from 'lib/json-ld/funeral-plans'
import {
  funeralPlanBreadCrumb,
  getStructuredBreadCrumbs,
} from 'lib/json-ld/getStructuredBreadCrumbs'
import { PRODUCTS } from 'lib/products/constants'
import JSONLD from 'components/JSONLD'
import {
  FF_EXPT_COST_COMPARE_FUNERAL_PLANS_LP_ENABLED,
  FUNERAL_PLAN_EMAIL,
  FUNERAL_PLAN_TELEPHONE_NUMBER,
  FUNERAL_PLAN_TELEPHONE_NUMBER_EXPERIMENT,
} from 'config'

import { useExperiment } from 'lib/hooks/useExperiment'
import { FORMATTED_CREMATION_FUNERAL_PLAN_FEE } from 'views/cremation/shared/constants'
import { useRecordSplitPageview } from 'lib/hooks/useRecordSplitPageview'

import Content from './Content'

const pageTitle = `Direct Cremation Funeral Plans for ${FORMATTED_CREMATION_FUNERAL_PLAN_FEE} - Arrange today with Farewill Funerals`
const pageDescription = `A simple prepaid funeral plan for ${FORMATTED_CREMATION_FUNERAL_PLAN_FEE}. No fuss and no hidden costs, just peace of mind.`

const FuneralPlans = (): React.ReactElement => {
  // ExptCostCompareFuneralPlansLP - Refactor this component after experiment is done
  const treatment = useExperiment('ExptCostCompareFuneralPlansLP')
  const { isReady } = useContext(SplitContext)
  const isExperiment =
    treatment === 'experiment_variant' &&
    FF_EXPT_COST_COMPARE_FUNERAL_PLANS_LP_ENABLED
  useRecordSplitPageview('funeral_plans_landing_page_view', undefined, {
    source: 'direct',
  })

  return (
    <>
      <JSONLD
        data={[
          FUNERAL_PLANS_JSONLD,
          getStructuredBreadCrumbs({
            itemListElements: [funeralPlanBreadCrumb],
          }),
        ]}
      />
      <DefaultLayout
        title={pageTitle}
        description={pageDescription}
        product={
          isExperiment
            ? PRODUCTS.FUNERAL_PLAN_EXPERIMENT
            : PRODUCTS.FUNERAL_PLAN
        }
        mobileHeaderColor={COLOR.WHITE}
        headerColor={isExperiment ? COLOR.ACCENT.PRIMARY_60 : COLOR.WHITE}
        headerStyle="funeralPlanSticky"
        email={FUNERAL_PLAN_EMAIL}
        telephoneNumber={
          isExperiment
            ? FUNERAL_PLAN_TELEPHONE_NUMBER_EXPERIMENT
            : FUNERAL_PLAN_TELEPHONE_NUMBER
        }
        footerShowFuneralPlansLegalInformation
        usePhoneNumberTracking={false}
      >
        {isReady ? <Content /> : <></>}
      </DefaultLayout>
    </>
  )
}

export default FuneralPlans
